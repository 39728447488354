export const generateHtmlSafeId = (id: string | number) => {
  const formattedId = String(id)
    .replace(/[^A-Z0-9]+/gi, '-')
    .toLowerCase()
  return formattedId && !isNaN(Number(formattedId.charAt(0))) ? `_${formattedId}` : formattedId
}

/**
 * This function calculates the target scroll position based on the element's
 * position relative to the viewport and then scrolls smoothly to it.
 * @param elementId - The ID of the element to scroll to
 * @param scrollPadding - Additional padding to adjust the final scroll position (default: 120px)
 *
 */
export const scrollToElement = (elementId: string, scrollPadding = 120) => {
  const element = document.getElementById(elementId)
  if (element) {
    const scrollPoint = element.getBoundingClientRect().top + window.scrollY - scrollPadding
    window.scroll({
      top: scrollPoint,
      behavior: 'smooth',
    })
  }
}

export function fuzzySearch(query: string, list: any[]) {
  // Convert query to lowercase for case-insensitive search
  query = query.toLowerCase()

  // Initialize an empty array to store results
  const matched: any[] = []
  const fuzzied: any[] = []

  // Iterate through each object in the list
  list.forEach((obj) => {
    // Convert object's name attribute to lowercase
    const itemName = obj.name.toLowerCase()

    let match = false
    let i = 0
    let j = 0

    // Iterate through each character in the query and object's name
    while (i < query.length && j < itemName.length) {
      // If characters match, move to the next character in both query and item name
      if (query[i] === itemName[j]) {
        i++
        j++
        match = true
      }
      // If characters don't match, move to the next character in the item name
      else {
        j++
      }
    }

    // Check if the entire query was found in the object's name
    if (match && i === query.length) {
      // Check if the query fully matches the name
      const isMatch = itemName.indexOf(query) >= 0
      if (isMatch) {
        matched.push({ ...obj })
      } else {
        fuzzied.push({ ...obj })
      }
    }
  })

  return { matched, fuzzied }
}

/**
 * This function returns a complete string outlining the total reviews
 * @param total - The total number of reviews
 *
 */
export const getTotalReviewsText = (total: number) => {
  return `${total} review${total > 1 ? 's' : ''}`
}
