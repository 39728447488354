import Icon from '@/components/Icons'
import Container from '@/components/Container'
import { Wrapper, NavContent, Logo, Left, Center, Right } from '@/components/MinimalTopbar.styles'
import { useRouter } from 'next/router'
import ProgressSteps from './ProgressSteps'
import { Body } from '@big-red-group/storefront-common-ui'

const MinimalTopbar = () => {

  return (
    <Wrapper>
      <Container>
        <NavContent>
          <Left>
            <Logo>
              <Icon className="desktop-logo" name="logoPrimary" />
            </Logo>
          </Left>
          <Center>
            <ProgressSteps />
          </Center>
          <Right>
            <Body color="text-inverse">Secure checkout</Body>
          </Right>
        </NavContent>
      </Container>
    </Wrapper>
  )
}

export default MinimalTopbar
