import type { Context, PageEventProperties } from '@segment/analytics-next'
import getConfig from 'next/config'

import {
  ANDROID_PLATFORM,
  CACHE_EXPIRY_DATE_KEY_NAME,
  IOS_PLATFORM,
  IOS_PLATFORMS,
  LINUX_PLATFORM,
  MACOS_PLATFORM,
  MACOS_PLATFORMS,
  MOBILE_DEVICE_PATTERN,
  NAVIGATOR_USER_AGENT_DATA_KEY,
  UNKNOWN_PLATFORM,
  WINDOWS_PLATFORM,
  WINDOWS_PLATFORMS,
} from '../constant/segment'
import { logger } from '../logging/Logger'

import { ProductBase } from '@/types/segment'
import { isValidDate } from './AnalyticsHelper'
import { getFBData, getGAData, getPagePerformanceData } from './ClientSideRenderHelper'
import { Options } from '@segment/analytics-next'

const log = logger()

/**
 * This plugin function will generate GA client ID, session ID and session number which will be placed into "Google Analytics"
 * property in both context and integrations object
 ********************
 * See https://redballoon.atlassian.net/wiki/spaces/BID/pages/2516353025/Common+Context+Properties for more details
 */
export const enrichGAContext = (ctx: Context): Context => {
  try {
    const ga = getGAData()

    if (ga) {
      const { clientId, sessionId, sessionNumber } = ga

      if (clientId) {
        ctx.event.context = {
          ...ctx.event.context,
          integrations: {
            ...ctx.event.context?.integrations,
            'Google Analytics': {
              clientId: clientId,
              ...(sessionId ? { sessionId } : {}),
              ...(sessionNumber ? { sessionNumber } : {}),
            },
          },
        }
      }
    }
  } catch (error) {
    log.error(
      'Segment plugin encountered error when enriching context data for GA',
      { pluginName: 'enrichGAContext' },
      error as Error
    )
  }

  return ctx
}

/**
 * This plugin function will generate the brand storefront context (`tracked_from`), client/server indicator (`is_server`),
 * mobile device indicator (`is_mobile`) and `platform` name which will be placed into top level of "context" object
 *********************
 * **Please note:** Generating `platform` name is only relative until `userAgentData` value in `window.navigator` is officially
 * launched; it is currently in experimental mode only.
 *********************
 * See https://redballoon.atlassian.net/wiki/spaces/BID/pages/2516353025/Common+Context+Properties for more details
 */
export const enrichCommonContext = (siteName: string, currencyCode?: string): ((ctx: Context) => Context) => {
  return (ctx: Context) => {
    try {
      const config = getConfig()
      const appVersion = config?.publicRuntimeConfig?.version

      // This is to prevent reference error due to unsupported browser since this feature is currently just experimental
      if (NAVIGATOR_USER_AGENT_DATA_KEY in window.navigator) {
        const { mobile: isMobile = false, platform = UNKNOWN_PLATFORM } = window.navigator.userAgentData as any

        ctx.event.context = {
          ...ctx.event.context,
          is_mobile: isMobile,
          platform,
        }
      }
      // Otherwise, it can be detected by using regular expression
      else {
        const { userAgent } = window.navigator

        ctx.event.context = {
          ...ctx.event.context,
          is_mobile: new RegExp(MOBILE_DEVICE_PATTERN, 'i').test(userAgent),
        }

        if (MACOS_PLATFORMS.findIndex((platform) => userAgent.indexOf(platform) !== -1) !== -1) {
          // This is the same value as returned by navigator.userAgentData.platform
          ctx.event.context = { ...ctx.event.context, platform: MACOS_PLATFORM }
        } else if (IOS_PLATFORMS.findIndex((platform) => userAgent.indexOf(platform) !== -1) !== -1) {
          ctx.event.context = { ...ctx.event.context, platform: IOS_PLATFORM }
        } else if (WINDOWS_PLATFORMS.findIndex((platform) => userAgent.indexOf(platform) !== -1) !== -1) {
          ctx.event.context = { ...ctx.event.context, platform: WINDOWS_PLATFORM }
        } else if (new RegExp(ANDROID_PLATFORM, 'i').test(userAgent)) {
          ctx.event.context = { ...ctx.event.context, platform: ANDROID_PLATFORM }
        } else if (new RegExp(LINUX_PLATFORM, 'i').test(userAgent)) {
          ctx.event.context = { ...ctx.event.context, platform: LINUX_PLATFORM }
        } else {
          const platformMatch = userAgent.match(new RegExp(MOBILE_DEVICE_PATTERN, 'i'))

          if (platformMatch) {
            const [platform] = new Set(platformMatch)

            ctx.event.context = { ...ctx.event.context, platform }
          } else {
            ctx.event.context = { ...ctx.event.context, platform: UNKNOWN_PLATFORM }
          }
        }
      }

      ctx.event.context = {
        ...ctx.event.context,
        tracked_from: ctx.event.context.tracked_from || siteName,
        entity_id:
          window?.document?.getElementsByTagName?.('meta')?.namedItem('pageid')?.getAttribute('content') || 'default',
        entity_type:
          window?.document?.getElementsByTagName?.('meta')?.namedItem('entityType')?.getAttribute('content') || 'NONE',
        entity_name:
          window?.document?.getElementsByTagName?.('meta')?.namedItem('entityName')?.getAttribute('content') || 'None',
        is_server: false,
        ...(appVersion ? { app_version: appVersion } : {}),
        page: { ...(ctx.event?.context?.page || {}), url: window.location.href },
      }

      if (ctx.event.type === 'track' || ctx.event.type === 'page') {
        // Just a type guard since Segment analytics.track() call has event name as required but analytics.page() call does not
        const { event: eventName = 'Page Viewed' } = ctx.event
        const eventLabel = eventName.toLowerCase().replace(/\s/g, '_')

        ctx.event.properties = {
          ...ctx.event.properties,
          event_label: eventLabel,
        }

        if (eventName === 'Page Viewed') {
          ctx.event.properties = { ...ctx.event.properties, event_category: 'PageView' }
        } else if (/^cart|checkout|order|product\s(?!session)/gi.test(eventName)) {
          ctx.event.properties = { ...ctx.event.properties, event_category: 'ECommerce' }

          if (eventName === 'Order Completed') {
            // Check if session expiry date exists in localStorage to indicate whether this is a guest mode or not
            const sessionExpiryDate = window.localStorage.getItem(CACHE_EXPIRY_DATE_KEY_NAME)

            ctx.event.context = {
              ...ctx.event.context,
              // Validate session expiry date value to ensure it's coming from Segment handler
              guest_mode: isValidDate(sessionExpiryDate),
            }
          }
        } else if (/^button|image|live\schat|product\ssession|video|dropdown/gi.test(eventName)) {
          ctx.event.properties = { ...ctx.event.properties, event_category: 'Interaction' }
        } else if (/page\s(bounced|exited)/gi.test(eventName)) {
          ctx.event.properties = { ...ctx.event.properties, event_category: 'Navigation' }

          const { event_date, ...eventPropsWithoutEventDate } = ctx.event.properties as PageEventProperties

          if (event_date && eventPropsWithoutEventDate) {
            ctx.event.properties = eventPropsWithoutEventDate
            ctx.event.timestamp = event_date
          }

          ctx.event.integrations = {
            All: true,
            ...ctx.event.integrations,
            'Google Analytics 4 Web': false,
            'Google Tag Manager': false,
          }
        } else if (/^error/gi.test(eventName)) {
          ctx.event.properties = { ...ctx.event.properties, event_category: 'Audit' }
        } else {
          ctx.event.properties = { ...ctx.event.properties, event_category: 'Other' }
        }
      }

      // If the product property exists in the event then add the currency based on the region.
      if (ctx.event.type === 'track' && currencyCode) {
        // Add currency to a product: Product Viewed, Product Added/Removed, Product Session Checked/ Shown events, etc.
        if (ctx.event.properties?.currency) {
          ctx.event.properties.currency = currencyCode
        }

        // Add currency to all products in the array: Product List Viewed event, Cart Viewed, etc.
        if (ctx.event.properties?.products) {
          ctx.event.properties.products = ctx.event.properties.products.map((product: ProductBase) => {
            if (product?.currency) {
              product.currency = currencyCode
            }
            return product
          })
        }
      }
    } catch (error) {
      log.error(
        'Segment plugin encountered error when enriching common properties of context data',
        { pluginName: 'enrichCommonContext' },
        error as Error
      )
    }

    return ctx
  }
}

// This plugin will append page performance metrics to the context properties
export const performanceMeasureContext = (ctx: Context): Context => {
  try {
    const pagePerformanceData = getPagePerformanceData()

    if (pagePerformanceData && ctx?.event?.context) {
      ctx.event.context.page_performance = pagePerformanceData
    }
  } catch (error) {
    log.error(
      'Segment plugin encountered error when getting performance measure',
      { pluginName: 'performanceMeasureContext' },
      error as Error
    )
  }

  return ctx
}

/**
 * This plugin function will generate Facebook FB fbp, and fbc (optional) which will be placed into "Facebook Analytics"
 * property in both context and integrations object
 */
export const enrichFBContext = (ctx: Context): Context => {
  try {
    const fb = getFBData()

    if (fb) {
      const { fbp, fbc } = fb

      if (fbp) {
        ctx.event.context = {
          ...ctx.event.context,
          integrations: {
            ...ctx.event.context?.integrations,
            // These values only come from FB Pixel integration
            'Facebook Pixel': {
              fbp,
              ...(fbc ? { fbc } : {}),
            },
          },
        }
      }
    }
  } catch (error) {
    log.error(
      'Segment plugin encountered error when enriching context data for FB',
      { pluginName: 'enrichFBContext' },
      error as Error
    )
  }

  return ctx
}

export const enrichGoogleAdsContext = (ctx: Context): Context => {
  try {
    const gclid = window?.sessionStorage?.getItem('gclid')
    if (gclid) {
      ctx.event.context = {
        ...ctx.event.context,
        integrations: {
          ...ctx.event.context?.integrations,
          'Google Ads': {
            ...(gclid ? { gclid } : {}),
          },
        },
      }
    }
  } catch (error) {
    log.error(
      'Segment plugin encountered error when enriching context data for Google Ads Conversions',
      { pluginName: 'enrichGoogleAdsContext' },
      error as Error
    )
  }

  return ctx
}

export const utmParametersContexts = (options: Options): ((ctx: Context) => Context) => {
  return (ctx: Context) => {
    try {
      if (options) {
        ctx.event.context = {
          ...ctx.event.context,
          ...(options.context?.campaign ? { campaign: options.context?.campaign } : {}),
        }
      }
    } catch (error) {
      log.error(
        'Segment plugin encountered error when enriching UTM properties of context data',
        { pluginName: 'utmParametersContexts' },
        error as Error
      )
    }
    return ctx
  }
}
