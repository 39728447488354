import styled from 'styled-components'
import { Popover, Transition } from '@headlessui/react'
import { SwiperButtons, SwiperLeft, SwiperRight } from './Swiper.styles'
import { Wrapper as LabelWrapper } from './Label.styles'
import { BREAKPOINT_SMALL } from '@/constant/ui'
import { Wrapper as ProductTileWrapper } from './ProductTile.styles'

export const BREAKPOINT_MOBILE_MENU = 1100

export const SearchInput = styled.div<{ hideMobile?: boolean; hideDesktop?: boolean }>`
  position: relative;

  > svg {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    color: var(--brand-secondary);
  }

  &.open {
    z-index: 10;
    @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
      position: fixed;
      top: 110px;
      left: 20px;
      width: calc(100% - 40px);
      z-index: 999;
    }
    @media (max-width: 768px) {
      top: 90px;
    }
    .search-icon {
      color: var(--brand-secondary);
    }
  }

  .loading {
    left: unset;
    right: 36px;
  }

  .search-icon {
    pointer-events: none;
    @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
      width: 24px;
      height: 24px;
      color: var(--text-inverse);
    }
  }

  .search-clear {
    left: unset;
    right: 15px;
    cursor: pointer;
    pointer-events: none;
  }
  &:not(.open) {
    .search-clear,
    .loading {
      @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
        display: none;
      }
    }
  }
`

export const SearchMobileInputWrapper = styled.div`
  display: none;
  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    display: block;
  }
`

export const SelectMenu = styled.div`
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  width: 100px;
  border-right: 1px solid var(--grey-10);

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border-top: 6px solid var(--grey-10);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-radius: 3px;
    transition: 0.15s ease-in-out;
  }

  select {
    appearance: none;
    border: 0;
    width: 100%;
    background: transparent;
    font-family: var(--font-bold);

    &:focus {
      outline: none;
    }
  }
`

export const QueryInput = styled.input`
  border: 1px solid var(--grey-10);
  border-radius: 4px;
  width: 320px;
  height: 40px;
  box-sizing: border-box;
  padding: 5px 15px 5px 42px;
  font-size: 14px;
  font-family: var(--font-medium);
  background-color: white;

  &:focus {
    border-color: var(--bg-brand);
    outline: none;
  }

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    &:not(.open) {
      font-size: 16px;
      width: 24px;
      height: 24px;
      border: 0;
      background-color: transparent;
      padding: 5px 15px 5px 36px;
    }
    &.open {
      width: 100%;
    }
  }
`

export const PopoverWrapper = styled(Popover)<{}>`
  position: relative;

  .transition {
    transition-property:
      color,
      background-color,
      border-color,
      text-decoration-color,
      fill,
      stroke,
      opacity,
      box-shadow,
      transform,
      filter,
      -webkit-backdrop-filter;
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow,
      transform, filter, backdrop-filter;
    transition-property:
      color,
      background-color,
      border-color,
      text-decoration-color,
      fill,
      stroke,
      opacity,
      box-shadow,
      transform,
      filter,
      backdrop-filter,
      -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .opacity-0 {
    opacity: 0;
  }

  .opacity-100 {
    opacity: 1;
  }

  .opacity-30 {
    opacity: 0.3;
  }

  .translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .translate-y-1 {
    --tw-translate-y: 0.25rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
      skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .duration-150 {
    transition-duration: 150ms;
  }

  .duration-200 {
    transition-duration: 200ms;
    @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
      transition-duration: 0ms;
    }
  }

  .ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }

  .ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  .fixed {
    position: fixed;
  }

  .inset-0 {
    inset: 0px;
  }

  .bg-black {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  }
`

export const PopoverPanelWrapper = styled(Popover.Panel)<{}>`
  margin-top: 6px;
  position: absolute;
  z-index: 10;
  background: white;
  border: 1px solid var(--brand-secondary);
  border-radius: 4px;
  padding: 32px 48px;
  width: 800px;
  left: 0px;
  overflow: hidden;
  outline: none;
  box-sizing: border-box;

  &:focus,
  &:active {
    outline: none;
  }

  .loading {
    position: absolute;
    left: 50%;
    top: 3px;
  }

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    position: fixed;
    margin-top: 0;
    width: 100%;
    border-radius: 0;
    border: 0;
    left: 0;
    top: 0;
    height: 100%;
    overflow: auto;
    padding-top: 86px 24px 32px 24px;
    right: 0;
  }
`

export const SearchSuggestionColumn = styled.div`
  display: grid;
  gap: 24px;

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    gap: 30px;
    margin-top: 60px;
  }
`
export const ResultsWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 200px) minmax(0, 560px);
  align-items: flex-start;
  gap: 48px;

  .swiper {
    width: 100%;
    overflow: hidden;
  }

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    ${LabelWrapper} {
      font-size: 24px;
    }
  }

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    grid-template-columns: 100%;
    gap: 30px;
  }
`

export const FuzzText = styled.span<{ marginRight?: number }>`
  text-decoration: none;
  cursor: pointer;
  color: var(--brand-secondary);
  ${({ marginRight }) =>
    marginRight &&
    `
   margin-right: ${marginRight}px;
  `}

  &:hover {
    text-decoration: underline;
  }
`

export const LiItemWrapper = styled.li`
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`

export const ClearHistoryBtn = styled.div`
  text-decoration: underline;
  cursor: pointer;
`

export const MobileTopWrapper = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  top: 0;
  background-color: var(--brand-secondary);
  color: var(--text-inverse);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  line-height: 1;

  @media (min-width: ${BREAKPOINT_MOBILE_MENU + 1}px) {
    display: none;
  }

  > div {
    text-align: center;
  }

  .logo {
    width: 120px;
  }

  .close {
    width: 22px;
  }
`

export const LinksWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 24px;

  > span {
    margin-bottom: 4px;
  }

  a {
    font-size: 14px;
    font-family: var(--font-medium);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    text-decoration: none;
  }

  svg {
    position: relative;
    top: 1px;
    width: 15px;
    height: 15px;
  }
`

export const Links = styled.ul`
  margin-top: 16px;
  font-family: var(--font-medium);

  li {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
    color: var(--text-dark);
    line-height: 1.15;
    width: fit-content;
    font-size: 14px;

    &:hover {
      text-decoration: underline;
    }
  }

  .view-all {
    display: none;

    a {
      text-decoration: underline;
      color: var(--text-semi-light);
    }
  }

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    li {
      margin-bottom: 16px;
    }
  }
`

export const ResetIcon = styled.div`
  /* position: absolute; */
  top: 50%;
  /* transform: translateY(-50%); */
  right: 15px;
  z-index: 2;
  width: 20px;
  height: 20px;
  color: var(--text-primary-light);
  cursor: pointer;
  pointer-events: all;

  svg {
    pointer-events: inherit;
  }
`

export const CloseIcon = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  > svg {
    width: 24px;
  }
  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    display: none;
  }
`

export const ButtonContainer = styled.div`
  position: relative;
  top: 50px;
  z-index: 2;
`

export const ProductResults = styled.div`
  position: relative;

  .product-tile__image {
    height: 110px;
  }
  p {
    font-size: 14px;
    line-height: 1.3;
  }
  .product-tile__name {
    font-family: var(--font-regular);
    font-size: 13px;
    line-height: 1.3;
  }
  .product-tile__supplier {
    display: none;
  }
  .product-tile__main {
    margin-bottom: 5px;
  }

  .see-more {
    padding: 10px 15px;
    width: 200px;
  }

  ${SwiperButtons} {
    position: absolute;
    top: 90px;
    z-index: 2;
    left: -20px;
    width: calc(100% + 30px);
  }
  ${SwiperLeft},
  ${SwiperRight} {
    background: white;
    box-sizing: border-box;
    border: 1px solid var(--brand-accent-secondary-dark);
    border-radius: 4px;
    width: 30px;
    height: 30px;

    &:hover {
      border-color: var(--brand-secondary);
      opacity: 1;
    }

    &:before {
      border-color: var(--text-primary);
    }

    &.swiper-button-disabled {
      border-color: var(--grey-100);
      pointer-events: none;
      opacity: 0;

      &:before {
        border-color: var(--grey-100);
      }
    }
  }
  ${SwiperLeft}::before {
    left: 56%;
  }
  ${SwiperRight}::before {
    left: 44%;
  }

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    .see-more {
      width: calc(25% - 12px);
    }
  }

  @media (max-width: ${BREAKPOINT_SMALL}px) {
    .swiper-slide {
      width: 200px;
      ${ProductTileWrapper} {
        width: 100%;
      }
    }
    .see-more {
      width: 100%;
      padding: 10px;

      p {
        font-size: 15px;
      }
    }
  }
`

export const NoResults = styled.div`
  font-family: var(--font-regular);
  color: var(--text-semi-light);
`

export const SearchHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`

export const SectionWrapper = styled.div`
  a {
    color: inherit;
  }

  @media (max-width: ${BREAKPOINT_MOBILE_MENU}px) {
    margin-left: auto;
  }
`
