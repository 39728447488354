import { OrderCompletedEventProperties, OrderListItemProperties, ProductBaseProperties } from '@segment/analytics-next'

import { DEFAULT_CURRENCY_CODE, DEFAULT_LANGUAGE } from '@/constant/segment'
import { calculateAmountCashPaid, getUrlSegmentFromUrl } from '@/helpers/AnalyticsHelper'
import { generateCustomerId } from '@/helpers/AnalyticsSegmentHelper'
import { roundDecimalNumber } from '@/helpers/ClientSideRenderHelper'
import { AnalyticsTrackEvent, CachedCart, LoyaltyCampaign, PaymentSummary } from '@/types'
import { CartItemData, centsToDollars } from '@big-red-group/storefront-common-checkout'
import { ConfirmationItemProps, Purchase } from '@big-red-group/storefront-common-checkout/dist/types'

// TODO: OMS
const processCartOptionData = (cartItem: CartItemData, orderListItemProperties?: OrderListItemProperties[]) => {
  // const { options: cartItemOptions } = cartItem
  // // Refer to types.ts, type AddEXOZGiftResponse, line 1462 for more info
  // const isGift = cartItem.type === 'GIFT_VOUCHER'
  // const productBaseProperties = makeProductBaseProperties({ product: cartItem })
  //
  // if (!cartItemOptions?.length) {
  //   if (orderListItemProperties?.length) {
  //     return orderListItemProperties.map<ProductBaseProperties & OrderListItemProperties>((orderListItem) => ({
  //       ...productBaseProperties,
  //       ...orderListItem,
  //       is_gift: isGift,
  //     }))
  //   }
  //
  //   return [productBaseProperties]
  // }
  //
  // return cartItemOptions.map<ProductBaseProperties & OrderListItemProperties>(
  //   ({ id, metadata, quantity, unitPrice }) => ({
  //     ...productBaseProperties,
  //     is_gift: isGift,
  //     variant_id: id,
  //     variant_name: metadata.productOptionName || '',
  //     session_id: roundDecimalNumber(Number(metadata.productSessionId) || 0, 0),
  //     session_name: metadata.productSessionName || '',
  //     quantity: quantity,
  //     price: unitPrice,
  //     ...(metadata.productOptionName ? { name: `${productBaseProperties.name} | ${metadata.productOptionName}` } : {}),
  //   })
  // )
}

/**
 * For taxonomy of this event, see https://redballoon.atlassian.net/wiki/spaces/BID/pages/2514059657/Order+Completed#Properties
 */
// TODO: OMS
export const createOrderCompletedEvent = (
  purchase: Purchase,
  cachedCart: CachedCart = {},
  total: number,
  subtotal: number,
  orderConfirmationItems: ConfirmationItemProps[],
  agentHandlerKey?: string,
  loyaltyCampaign?: LoyaltyCampaign,
  coupon?: any, // TODO: add coupon type
  paymentSummaries?: PaymentSummary[],
  shippingFee?: number
) => {
  const { id, buyer } = purchase.purchase

  const { extraFields } = buyer
  // const { items = {}, metadata } = cachedCart // TODO

  const eventProperties: OrderCompletedEventProperties = {
    order_id: id,
    subtotal: centsToDollars(subtotal) || 0,
    total: total || 0,
    products: [],
  }

  if (coupon) {
    const { code, discountedAmount } = coupon
    eventProperties.coupon = code
    eventProperties.discount = roundDecimalNumber(discountedAmount)
  }

  if (paymentSummaries?.length) {
    const payment_details = paymentSummaries?.map((payment) => ({
      payment_method: payment.paymentMethod,
      amount: payment.amount,
    }))
    eventProperties.payments = payment_details

    eventProperties.amount_cash = calculateAmountCashPaid(paymentSummaries)
  }
  if (shippingFee) {
    eventProperties.shipping_fee = shippingFee
  }

  if (orderConfirmationItems?.length) {
    eventProperties.products = orderConfirmationItems.reduce<(ProductBaseProperties & OrderListItemProperties)[]>(
      (productList, item) => {
        const { id, allOptions, name, image, type, price: itemPrice, url } = item

        const orderListItemProperties = allOptions
          ? allOptions.map<OrderListItemProperties>(({ id, name, quantity, price: optionPrice }) => ({
              variant_id: id,
              variant_name: name,
              session_id: 0, // TODO
              session_name: '', // TODO
              quantity: quantity,
              price: optionPrice,
            }))
          : []

        // TODO
        // const cachedCartItem = items[id.toString()]

        // if (cachedCartItem) {
        //   // return [...productList, ...processCartOptionData(cachedCartItem, orderListItemProperties)]
        //   console.log('cachedCartItem..', cachedCartItem)
        // } else {
        const productBaseProperties: ProductBaseProperties = {
          product_id: Number(id),
          name: name,
          title: name,
          quantity: 1,
          url: `${window.location.origin}/${DEFAULT_LANGUAGE}/${url}`,
          url_segment: getUrlSegmentFromUrl(url, ''),
          image_url: image,
          is_recommendation: false,
          product_type: type,
        }

        /* If orderListItemProperties.length, then
         * return LIVE_BOOKING / EXPERIENCE_VOUCHER products with the productOptions,
         * else GIFT_VOUCHER product.
         */
        return [
          ...productList,
          ...(orderListItemProperties.length
            ? orderListItemProperties.map<ProductBaseProperties & OrderListItemProperties>((orderListItem) => ({
                ...productBaseProperties,
                ...orderListItem,
                is_gift: type === 'EXPERIENCE_VOUCHER' ? true : false,
              }))
            : [
                ...[
                  {
                    ...productBaseProperties,
                    is_gift: true,
                    price: itemPrice,
                  },
                ],
              ]),
        ]
        // }
      },
      []
    )
  }
  // TODO: metadata cache
  // else {
  //   const { total, discount, products } = Object.values(items).reduce<{
  //     total: number
  //     discount: number
  //     products: (ProductBaseProperties & OrderListItemProperties)[]
  //   }>(
  //     (acc, cartItem) => ({
  //       total: acc.total + cartItem.total,
  //       discount: acc.discount + Math.abs(cartItem.total - cartItem.subTotal) ?? 0,
  //       products: [...acc.products, ...processCartOptionData(cartItem)],
  //     }),
  //     {
  //       total: 0,
  //       discount: 0,
  //       products: [],
  //     }
  //   )

  //   eventProperties.discount = roundDecimalNumber(
  //     metadata?.total && metadata?.subTotal ? Math.abs(metadata.total - metadata.subTotal) : discount
  //   )
  //   eventProperties.total = roundDecimalNumber(metadata?.total ?? total)
  //   eventProperties.products = products
  // }

  const event: Extract<AnalyticsTrackEvent, { object: 'Order'; action: 'Completed' }> = {
    object: 'Order',
    action: 'Completed',
    properties: {
      ...eventProperties,
      ...(eventProperties.products.length
        ? { currency: eventProperties.products[0].currency || DEFAULT_CURRENCY_CODE }
        : {}),
    },
  }

  // TODO: Remove email, firstName, and lastName when server-side tracking is available
  if (buyer.email || buyer.firstName || buyer.lastName) {
    event.context = {
      ...event.context,
      traits: {
        ...event.context?.traits,
        ...(buyer.email ? { customer_id: generateCustomerId(buyer.email), email: buyer.email } : {}),
        ...(buyer.firstName ? { first_name: buyer.firstName } : {}),
        ...(buyer.lastName ? { last_name: buyer.lastName } : {}),
      },
    }
  }

  // Add additional loyalty campaign data if exists
  // if (agentHandlerKey) {
  //   switch (agentHandlerKey) {
  //     case 'virgin': {
  //       if (extraFields?.operationType) {
  //         const { operationType, burnPoints, cardAmount } = extraFields

  //         switch (operationType) {
  //           case 'burn': {
  //             const hasBurnPoints = burnPoints !== undefined && !Number.isNaN(Number(burnPoints))
  //             const hasCardAmount = cardAmount !== undefined && !Number.isNaN(Number(cardAmount))

  //             if (hasBurnPoints) {
  //               const points = Math.round(Number(burnPoints))

  //               event.properties.loyalty_campaign = event.properties.loyalty_campaign || []
  //               event.properties.loyalty_campaign.push({
  //                 operation_type: operationType,
  //                 points,
  //               })

  //               // In case card amount is unknown, compute from consumed points
  //               if (event.properties.total !== undefined && !hasCardAmount) {
  //                 const burnAmount = roundDecimalNumber(points * 0.006)
  //                 event.properties.cash_payable = roundDecimalNumber(Math.max(event.properties.total - burnAmount, 0))
  //                 event.properties.credit_deductible = burnAmount

  //                 if (event.properties.cash_payable && loyaltyCampaign?.pointsPerDollar) {
  //                   event.properties.loyalty_campaign.push({
  //                     operation_type: 'earn',
  //                     points: roundDecimalNumber(event.properties.cash_payable * loyaltyCampaign.pointsPerDollar, 0),
  //                   })
  //                 }
  //               }
  //             }

  //             if (hasCardAmount) {
  //               event.properties.cash_payable = roundDecimalNumber(Number(cardAmount))

  //               if (event.properties.total !== undefined) {
  //                 event.properties.credit_deductible =
  //                   (event.properties.credit_deductible ?? 0) +
  //                   roundDecimalNumber(event.properties.total - Number(cardAmount))
  //               }

  //               if (loyaltyCampaign?.pointsPerDollar) {
  //                 event.properties.loyalty_campaign = event.properties.loyalty_campaign || []
  //                 event.properties.loyalty_campaign.push({
  //                   operation_type: 'earn',
  //                   points: roundDecimalNumber(Number(cardAmount) * loyaltyCampaign.pointsPerDollar, 0),
  //                 })
  //               }
  //             }

  //             break
  //           }
  //           case 'earn': {
  //             if (loyaltyCampaign?.pointsPerDollar && event.properties.total !== undefined) {
  //               event.properties.cash_payable = event.properties.total
  //               event.properties.credit_deductible = event.properties.credit_deductible ?? 0

  //               event.properties.loyalty_campaign = event.properties.loyalty_campaign || []
  //               event.properties.loyalty_campaign.push({
  //                 operation_type: operationType,
  //                 points: roundDecimalNumber(event.properties.total * loyaltyCampaign.pointsPerDollar, 0),
  //               })
  //             }

  //             break
  //           }
  //           default:
  //             break
  //         }
  //       }

  //       break
  //     }
  //     default: {
  //       if (event.properties.total !== undefined) {
  //         event.properties.cash_payable = event.properties.total
  //         event.properties.credit_deductible = event.properties.credit_deductible ?? 0
  //       }

  //       break
  //     }
  //   }
  // }

  return event
}
