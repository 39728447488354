import { IconName } from '@/components/Icons'
import { ButtonStyles, SpacingSizes } from '@/types'

import { CSSProperties } from 'react'

/* Breakpoints */
export const BREAKPOINT_EXTRA_SMALL = 599
export const BREAKPOINT_SMALL = 768
export const BREAKPOINT_MEDIUM = 1020
export const BREAKPOINT_MEDIUM_LARGE = 1200
export const BREAKPOINT_LARGE = 1440

export const SPACING_SIZES: SpacingSizes = {
  XXXS: {
    name: 'XXXS',
    desktop: 4,
    mobile: 2,
  },
  XXS: {
    name: 'XXS',
    desktop: 8,
    mobile: 4,
  },
  XS: {
    name: 'XS',
    desktop: 12,
    mobile: 8,
  },
  S: {
    name: 'S',
    desktop: 16,
    mobile: 12,
  },
  M: {
    name: 'M',
    desktop: 24,
    mobile: 16,
  },
  L: {
    name: 'L',
    desktop: 32,
    mobile: 24,
  },
  XL: {
    name: 'XL',
    desktop: 40,
    mobile: 32,
  },
  XXL: {
    name: 'XXL',
    desktop: 64,
    mobile: 48,
  },
  XXXL: {
    name: 'XXXL',
    desktop: 128,
    mobile: 64,
  },
}

export const BUTTON_STYLE: ButtonStyles = {
  S: { name: 'S', paddingVertical: 6, paddingHorizontal: 10 },
  M: { name: 'M', paddingVertical: 10, paddingHorizontal: 12 },
  L: { name: 'L', paddingVertical: 14, paddingHorizontal: 26 },
}

export const PAYMENT_PAGE_PATH = 'en/order/payment/payment-router'
export const CHECKOUT_PAGE_PATH = 'en/order/checkout'

export const CHECKOUT_STEPS: Array<{ label: string; icon: IconName }> = [
  { label: 'Details', icon: 'pencil' },
  { label: 'Payment', icon: 'money' },
]

export const TOASTER_STYLE: CSSProperties = {
  maxWidth: '400px',
  width: '100%',
  padding: '0',
  animation: 'none',
}

export const TOASTER_CLASS_NAME = 'react-hot-toast-container'

/**
 * Z-INDEX MANAGEMENT
 *
 * This object centralizes z-index values to maintain consistency across the project and prevent 
 * conflicting stacking issues. Instead of using arbitrary z-index values, reference this dictionary.
 *
 *  Guidelines:
 * - Use existing values** whenever possible to maintain consistency.
 * - Adjust values relatively within a component (`Z_INDEX.popup + 1`, `Z_INDEX.tooltip - 1`, etc.) 
 * - Follow a logical layering approach when adding new elements.
 * - Avoid using excessively high values unless absolutely necessary.
 *
 **/

export const Z_INDEX = {
  base: 0,
  low: 1,
  medium: 2,
  footer: 2,
  dropdown: 3,

  backdrop: 10, // Used for backdrops, dimmed overlays
  midHigh: 10, // Used for arrows, icons, or minor floating elements

  high: 20, // Sticky components, floating buttons

  tooltip: 30, // Tooltips and small popups
  elevated: 50, // Wrappers for popups, dropdowns, modals

  topBar: 99, // Top bars, sticky headers, floating navigation
  popup: 99, // Popups, modal containers

  veryHigh: 100,
  superHigh: 9999, // Rare use cases (should be avoided unless necessary)
}

export const BASE_MOBILE_TOP_BAR_HEIGHT = 56
export const HAPPY_HOUR_BANNER_HEIGHT = 28