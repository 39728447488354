import { useCallback, useState } from 'react'
import { isEqual } from 'lodash'

import {
  createProductAddedEvent,
  createProductClickedEvent,
  createProductListViewedEvent,
  createProductRemovedEvent,
  createProductSessionCheckedEvent,
  createProductSessionShownEvent,
  createProductViewedEvent,
} from '@/events'
import type {
  CreateProductSessionCheckedEventInput,
  CreateProductSessionShownEventInput,
  GiftVoucherItem,
  PlpProduct,
  Product,
  ProductDetails,
  ProductOption,
  ProductRecommendation,
  ProductsSearchedEventInput,
  SegmentCartItem,
  SegmentCheckoutCartItemOption,
  VoucherDetails,
} from '@/types'

import { useAnalytics } from './AnalyticsHook'
import { useAnalyticsMetaData } from './AnalyticsMetaDataHook'
import { CartItemData, CartItemOptionData, ProductSession } from '@big-red-group/storefront-common-checkout'
import { createProductSearchedEvent } from '@/events/ProductsSearched'

type TrackProductClickedHook = {
  product: PlpProduct | Product | ProductRecommendation
  position: number
  isRecommended: boolean
  listTitle?: string
  listId?: string
  fromWidget?: boolean
  isCarousel?: boolean
}

type TrackProductAddedHook = {
  product: ProductDetails | CartItemData | GiftVoucherItem
  isProductEdit: boolean
  isGift: boolean
  quantity: number
  url: string
  // Optional inputs
  session?: Omit<ProductSession, 'productOptions'>
  variant?: ProductOption
  voucher?: VoucherDetails
}
type TrackProductRemovedHook = {
  product: SegmentCartItem
  url: string
  isGift: boolean
  isProductEdit: boolean
  // Optional inputs
  currency?: string
  options?: SegmentCheckoutCartItemOption[]
}

export const useProductClicked = () => {
  const analytics = useAnalytics()
  const metadata = useAnalyticsMetaData()

  const trackProductClicked = useCallback(
    ({
      product,
      position,
      isRecommended,
      listTitle = '',
      listId = '',
      fromWidget = false,
      isCarousel = false,
    }: TrackProductClickedHook) => {
      // Add item into cache for use later
      metadata.addItemToCache(product)
      const context = metadata.getContextOptions()

      const event = createProductClickedEvent({
        product,
        position,
        listId,
        listTitle,
        isRecommended,
        fromWidget,
        isCarousel,
      })

      if (context) {
        event.context = context
      }

      analytics.trackEvent(event)
    },
    [analytics, metadata]
  )

  return trackProductClicked
}

export const useProductViewed = () => {
  const analytics = useAnalytics()
  const metadata = useAnalyticsMetaData()

  const trackProductViewed = useCallback(
    (product: ProductDetails, isProductEdit: boolean, productLayout?: string) => {
      // When a product is viewed, add that product to the cache for use later
      metadata.addItemToCache(product)
      const context = metadata.getContextOptions()

      const event = createProductViewedEvent({ product, productLayout, isProductEdit })

      if (context) {
        event.context = context
      }

      analytics.trackEvent(event)
    },
    [analytics, metadata]
  )

  return trackProductViewed
}

export const useProductListViewed = (listTitle?: string, listId?: string) => {
  const analytics = useAnalytics()
  const metadata = useAnalyticsMetaData()

  const [previousTotalProducts, setPreviousTotalProducts] = useState<number | null>(null)

  const [previousProducts, setPreviousProducts] = useState<(PlpProduct | Product | ProductRecommendation)[]>([])

  const trackProductListViewed = useCallback(
    (
      products: (PlpProduct | Product | ProductRecommendation)[],
      isWidget: boolean = false,
      isPopularCategory: boolean = false,
      totalProductsCount?: number
    ) => {
      if (totalProductsCount) {
        // Compare previous state value with a new value
        const isEqualResults =
          previousTotalProducts && previousTotalProducts === totalProductsCount && isEqual(previousProducts, products)

        if (isEqualResults) {
          return
        }

        setPreviousTotalProducts(totalProductsCount!)
        setPreviousProducts(products)
      }

      const event = createProductListViewedEvent({
        products,
        listId,
        name: listTitle,
        isWidget,
        isPopularCategory,
        totalProductsCount,
      })
      analytics.trackEvent(event)

      for (const product of products) {
        metadata.addItemToCache(product)
      }
    },
    [analytics, metadata, listId, listTitle, previousTotalProducts, previousProducts]
  )

  return trackProductListViewed
}

export const useProductAdded = () => {
  const analytics = useAnalytics()

  const trackProductAdded = useCallback(
    ({ product, isProductEdit, isGift, quantity, url, session, variant, voucher }: TrackProductAddedHook) => {
      const event = createProductAddedEvent({
        product,
        url,
        isGift,
        quantity,
        isProductEdit,
        session,
        variant,
        voucher,
      })

      analytics.trackEvent(event)
    },
    [analytics]
  )

  return trackProductAdded
}

export const useProductRemoved = () => {
  const analytics = useAnalytics()
  const metadata = useAnalyticsMetaData()

  const trackProductRemoved = useCallback(
    ({ isProductEdit, isGift, product, url, currency, options }: TrackProductRemovedHook) => {
      const context = metadata.getContextOptions()

      if (options?.length) {
        for (const option of options) {
          const event = createProductRemovedEvent({
            product,
            url,
            isGift,
            latitude: 0,
            longitude: 0,
            isProductEdit,
            option,
            currency,
          })

          if (context) {
            event.context = context
          }

          analytics.trackEvent(event)
        }
      } else {
        const event = createProductRemovedEvent({
          product,
          url,
          isGift,
          latitude: 0,
          longitude: 0,
          isProductEdit,
          currency,
        })

        if (context) {
          event.context = context
        }

        analytics.trackEvent(event)
      }
    },
    [analytics, metadata]
  )

  return trackProductRemoved
}

export const useProductSessionChecked = (product: ProductDetails) => {
  const analytics = useAnalytics()
  const metadata = useAnalyticsMetaData()

  const trackProductSessionChecked = useCallback(
    ({
      isRecommended = false,
      listId,
      listName,
      sessionFrom,
      sessionTo,
      url,
      isProductEdit,
      bookingCalendarType,
    }: Omit<CreateProductSessionCheckedEventInput, 'product' | 'url'> & { url?: string }) => {
      const context = metadata.getContextOptions()

      const event = createProductSessionCheckedEvent({
        product,
        url: url || window?.location?.href || '',
        sessionFrom,
        sessionTo,
        listId,
        listName,
        isRecommended,
        isProductEdit,
        bookingCalendarType,
      })

      if (context) {
        event.context = context
      }

      analytics.trackEvent(event)
    },
    [analytics, metadata, product]
  )

  return trackProductSessionChecked
}

export const useProductSessionShown = (product: ProductDetails) => {
  const analytics = useAnalytics()
  const metadata = useAnalyticsMetaData()

  const trackProductSessionShown = useCallback(
    ({
      availableSessionLength,
      checkedSessionDate,
      otherSessionLength,
      productAllSessions,
      url,
      isProductEdit,
    }: Omit<CreateProductSessionShownEventInput, 'product' | 'url'> & { url?: string }) => {
      const context = metadata.getContextOptions()

      const event = createProductSessionShownEvent({
        product,
        url: url || window?.location?.href || '',
        checkedSessionDate,
        productAllSessions,
        availableSessionLength,
        otherSessionLength,
        isProductEdit,
      })

      if (context) {
        event.context = context
      }

      analytics.trackEvent(event)
    },
    [analytics, metadata, product]
  )

  return trackProductSessionShown
}

export const useProductsSearched = () => {
  const analytics = useAnalytics()

  const trackProductsSearched = useCallback(
    (query: ProductsSearchedEventInput) => {
      const event = createProductSearchedEvent(query)
      analytics.trackEvent(event)
    },
    [analytics]
  )

  return trackProductsSearched
}
