import { useContext, useState } from 'react'

import { Wrapper, ContentWrapper, HeadingWrapper, FormWrapper, InputWrapper } from './Subscriber.styles'
import Container from './Container'
import { Body, FlexibleHeading as Heading } from '@big-red-group/storefront-common-ui'
import Button from './Button'
import TextField from './TextField'
import { useRouter } from 'next/router'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import InputError from './InputError'
import { useAuthSession } from '@/hooks/AuthSessionHook'
import { VALID_EMAIL_REGEX } from '@big-red-group/storefront-common-checkout'
import Link from 'next/link'

const validationSchema = Yup.object({
  subscriberEmail: Yup.string()
    .required('Please enter your email address.')
    .matches(VALID_EMAIL_REGEX, 'Please enter a valid email address.'),
})

const Subscriber: React.FC = () => {
  const router = useRouter()
  const lang = router.query?.lang || 'en'
  const { unauthenticated } = useAuthSession()

  const formik = useFormik({
    initialValues: {
      subscriberEmail: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      let email = values.subscriberEmail
      router.push(`/${router.query?.lang ?? 'en'}/preferences`)
    },
  })

  const renderMessage = () => {
    return (
      <>
        <Heading size="L">UNLOCK $10</Heading>
        <Body size="S" weight="regular">
          Be the first to know about exclusive Crew offers, competitions and personalised inspiration straight to your
          inbox.
        </Body>
      </>
    )
  }

  const renderCTA = () => {
    return (
      <>
        <Body size="S" weight="regular">
          Which email do we send your $10 to?
        </Body>
        <InputWrapper>
          <TextField
            name="subscriberEmail"
            type="email"
            value={formik.values.subscriberEmail}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Email address"
          />
          {formik.touched.subscriberEmail && formik.errors.subscriberEmail && (
            <InputError dataTestId="subscriber-email-error-message">{formik.errors.subscriberEmail}</InputError>
          )}
          <Button size="M" onClick={formik.handleSubmit}>
            Sign me up
          </Button>
        </InputWrapper>
        <Body size="XS">
          $49 minimum spend to use $10 promo code. By subscribing you confirm that you have read, understood and accept
          our <Link href={`/${lang}/terms-and-conditions`} target='_blank'>Terms & Conditions</Link> and{' '}
          <Link href={`/${lang}/privacy-policy`} target='_blank'>Privacy Policy</Link>. You agree to receive electronic communications.
          You can opt-out at any time.
        </Body>
      </>
    )
  }

  return unauthenticated ? (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <HeadingWrapper>{renderMessage()}</HeadingWrapper>
          <FormWrapper>{renderCTA()}</FormWrapper>
        </ContentWrapper>
      </Container>
    </Wrapper>
  ) : (
    <></>
  )
}

export default Subscriber
