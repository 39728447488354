import { PlpProduct, SearchCategory, SearchPropertiesCategoryEntity, SearchQuery, SortBy } from '@/types'
import regions from '../json/regionDetails.json'
import fuzzysort from 'fuzzysort'
import { format } from 'date-fns'
import { toName } from './UrlHelper'

const rangePattern = /^(\d{4}-\d{2}-\d{2}_\d{4}-\d{2}-\d{2})$/g
const datePattern = /^(\d{4}-\d{2}-\d{2})$/g

export const isDate = (date: string) => {
  return datePattern.test(date)
}

export const isDateRange = (range: string): boolean => {
  return rangePattern.test(range)
}

const extractDates = (datePath: string | undefined): undefined | [Date, Date] => {
  const dateBits = datePath?.match(rangePattern)

  if (!dateBits || dateBits[0].indexOf('_') === -1) return undefined

  const splitDate = dateBits[0].split('_')
  return [new Date(splitDate[0]), new Date(splitDate[1])]
}

export const extractDateRange = (searchQuery: SearchQuery): [Date, Date] | undefined => {
  const isPart2Date = searchQuery.part2 !== undefined && rangePattern.test(searchQuery.part2)
  const isPart3Date = searchQuery.part3 !== undefined && rangePattern.test(searchQuery.part3)

  return isPart2Date ? extractDates(searchQuery.part2) : isPart3Date ? extractDates(searchQuery.part3) : undefined
}

export const searchLocation = (location: string): [string, string][] => {
  const results = fuzzysort.go(location, regions, { keys: ['name', 'stateName'] })
  return results.map((result) => {
    return [result.obj.name, result.obj.urlSegment]
  })
}

// Formats a search query date
const formatDate = (date: string) => {
  const datesArray = date.split('_')

  const formattedDates = datesArray.map((date) => {
    return format(Date.parse(date), 'EEE, MMM do')
  })

  const formattedString = formattedDates.join(' - ')
  return formattedString
}

// Determines what part each param of a particular search query is
export const formatSearchParams = (
  part1Type: string | null,
  part2Type: string | null,
  part1: string | null,
  part2?: string,
  part3?: string
) => {
  if (!part1) {
    return {
      part1: 'Locations',
      part2: 'Experiences',
      part3: 'Date',
    }
  }
  // Region with or without date
  if (part1Type === 'REGION' && !part2Type) {
    return {
      part1: toName(part1),
      part2: null,
      part3: part2 && formatDate(part2),
    }
  }
  // Category with or without date
  if ((part1Type === 'CATEGORY' || part1Type === 'SUBCATEGORY') && !part2Type) {
    return {
      part1: null,
      part2: toName(part1),
      part3: part2 && formatDate(part2),
    }
  }
  // Region, Category and no date
  if (part1Type === 'REGION' && part2Type === 'CATEGORY' && !part3) {
    return {
      part1: toName(part1),
      part2: toName(part2!),
      part3: null,
    }
  }
  // Region, Category and date
  if (part1Type === 'REGION' && part2Type === 'CATEGORY' && part3) {
    return {
      part1: toName(part1),
      part2: toName(part2!),
      part3: formatDate(part3),
    }
  }
  return {
    part1: null,
    part2: null,
    part3: null,
  }
}

export const sortProducts = (sortParam: SortBy, sortData: PlpProduct[], originalSortData?: PlpProduct[]) => {
  switch (sortParam.name) {
    case 'popular':
      return originalSortData ? originalSortData : sortData
    case 'priceHigh': {
      const sorted = [...sortData]
      sorted.sort((prod1, prod2) => {
        return prod2.retailPrice - prod1.retailPrice
      })
      return sorted
    }
    case 'priceLow': {
      const sorted = [...sortData]
      sorted.sort((prod1, prod2) => {
        return prod1.retailPrice - prod2.retailPrice
      })
      return sorted
    }
    case 'ratingHigh': {
      const sorted = [...sortData]
      sorted.sort((prod1, prod2) => {
        const prod1Rating = prod1.rating || 0
        const prod2Rating = prod2.rating || 0
        return prod2Rating - prod1Rating
      })
      return sorted
    }
    case 'alphabeticallyHigh': {
      const sorted = [...sortData]
      sorted.sort((prod1, prod2) => {
        const prod1Name = prod1.name.toLowerCase()
        const prod2Name = prod2.name.toLowerCase()
        if (prod1Name < prod2Name) {
          return -1
        }
        if (prod1Name > prod2Name) {
          return 1
        }
        return 0
      })
      return sorted
    }
    case 'alphabeticallyLow': {
      const sorted = [...sortData]
      sorted.sort((prod1, prod2) => {
        const prod1Name = prod1.name.toLowerCase()
        const prod2Name = prod2.name.toLowerCase()
        if (prod1Name < prod2Name) {
          return 1
        }
        if (prod1Name > prod2Name) {
          return -1
        }
        return 0
      })
      return sorted
    }
  }
}

export const formatSearchTitle = (
  part1Type: string | null,
  part2Type: string | null,
  part1: string | null,
  part2?: string
) => {
  // No search terms
  if (!part1) {
    return 'All Experiences'
  }
  // Region + category
  if (part1Type === 'REGION' && part2Type === 'CATEGORY' && part2) {
    return `${toName(part1)} ${toName(part2)}`
  }
  // Region, no category
  if (part1Type === 'REGION' && !part2Type) {
    return `${toName(part1)} Experiences`
  }
  // Category, no region
  if ((part1Type === 'CATEGORY' || part1Type === 'SUBCATEGORY') && !part2Type) {
    return `All ${toName(part1)}`
  }
}

export function flattenCategories(categories?: SearchPropertiesCategoryEntity[]): SearchCategory[] {
  return (categories || []).reduce((acc: SearchCategory[], category) => {
    acc.push({
      id: category.id,
      name: category.name,
      urlSegment: category.urlSegment,
      isParent: true,
    })
    acc = acc.concat([...(category.subCategories || [])])
    return acc
  }, [])

}

export function pluralizeCompare(valA: string, valB: string) {
  const a = valA.replace(/[\s-_]+/g, '').toLowerCase();
  const b = valB.replace(/[\s-_]+/g, '').toLowerCase();
  return a === b || a === `${b}s`
}