import { SearchModalAction, SearchModalToggleAction } from '@segment/analytics-next'

import { AnalyticsTrackEvent, ModalTypes, SearchInteractionInput } from '@/types'
import { SearchBarProduct } from '@/types/segment'

export const createSearchModalToggleEvent = (
  toggle: SearchModalToggleAction,
  placement: string,
  type: ModalTypes
): Extract<AnalyticsTrackEvent, { object: 'Search Modal'; action: Exclude<SearchModalAction, 'Interaction'> }> => {
  if (toggle === 'off') {
    return {
      object: 'Search Modal',
      action: 'Closed',
      properties: { placement, type },
    }
  }

  return {
    object: 'Search Modal',
    action: 'Opened',
    properties: { placement, type },
  }
}

export const createSearchModalInteractionEvent = (
  searchInput: SearchInteractionInput,
  placement: string,
  type: ModalTypes
): Extract<AnalyticsTrackEvent, { object: 'Search Modal'; action: 'Interaction' }> => {
  const {
    searchedInput = '',
    searchedValue = '',
    autocorrectValues,
    suggestedCategories,
    suggestedLocations,
    isAutoCorrectionClicked = false,
    isLocationSearched = false,
    isCategorySearched = false,
    isSuggestedLocationClicked = false,
    isSuggestedCategoryClicked = false,
    isProductClicked = false,
    totalProducts,
    displayProducts,
    product,
  } = searchInput

  // TODO: Make product base properties when product type is defined
  // TODO: If geolocation list is available in any input type, set it up in the payload
  // const productProperties = displayProducts?.map((product, index) => ({
  //   ...makeProductBaseProperties({ product, position: index + 1, includeSessions: true }),
  // }))

  const updatedAutocorrectValues = autocorrectValues?.map((value) => {
    return {
      id: value.id,
      name: value.name,
      url_segment: value.urlSegment,
    }
  })

  const updatedSuggestedCategories = suggestedCategories?.map((value) => {
    return {
      id: value.id,
      name: value.name,
      url_segment: value.urlSegment,
      is_parent: value.isParent,
    }
  })
  const updatedSuggestedLocations = suggestedLocations?.map((value) => {
    return {
      id: value.id,
      name: value.name,
      url_segment: value.urlSegment,
    }
  })

  const transformedProduct = (product: any): SearchBarProduct => {
    return {
      product_id: product?.id,
      name: product?.name,
      brand: product?.operatorName,
      canonical_region_url_segment: product?.canonicalRegionUrlSegment,
      url_segment: product?.urlSegment,
      price: product?.fromPrice,
      images: product?.images,
    }
  }

  return {
    object: 'Search Modal',
    action: 'Interaction',
    properties: {
      placement,
      type,
      searched_input: searchedInput,

      ...(autocorrectValues?.length
        ? {
            corrections: updatedAutocorrectValues!,
          }
        : {}),
      ...{
        categories: {
          searched_value: isCategorySearched ? searchedValue! : '',
          suggestions: updatedSuggestedCategories! || [],
          is_suggestion_clicked: isSuggestedCategoryClicked,
          is_correction_clicked: isCategorySearched ? isAutoCorrectionClicked : false,
        },
      },
      ...{
        locations: {
          searched_value: isLocationSearched ? searchedValue! : '',
          suggestions: updatedSuggestedLocations! || [],
          is_suggestion_clicked: isSuggestedLocationClicked,
          is_correction_clicked: isLocationSearched ? isAutoCorrectionClicked : false,
        },
      },
      ...(displayProducts?.length
        ? {
            product_list: {
              searched_value: isProductClicked ? searchedValue! : '',
              total_products: displayProducts.length!,
              products: displayProducts?.map((product) => transformedProduct(product))!,
              product: product ? transformedProduct(product)! : undefined,
              is_suggestion_clicked: isProductClicked ? (searchedInput.length >= 3 ? true : false) : false,
              is_popular_clicked: isProductClicked ? (searchedInput.length >= 3 ? false : true) : false,
            },
          }
        : {}),
    },
  }
}
