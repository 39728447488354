declare const window: any

export const initChat = (delaySeconds: number = 0, handlerKey: string | undefined) => {
  if (process.env.NODE_ENV === 'development') {
    return
  }
  window.setTimeout(() => {
    try {
      if (!window.embedded_svc) {
        // Add chat script if not exists
        const scriptElement = document.createElement('script')
        scriptElement.setAttribute('src', 'https://thebigredgroup.my.salesforce.com/embeddedservice/5.0/esw.min.js')
        scriptElement.onload = function () {
          initESW(null)
        }
        document.body.appendChild(scriptElement)
      } else {
        initESW('https://service.force.com')
      }
    } catch (error) {
      console.log('Error initialising live chat')
    }
  }, delaySeconds * 1000)
}

const initESW = (gslbBaseURL: string | null) => {
  window.embedded_svc.settings.smallCompanyLogoImgURL =
    'https://cdn.experienceoz.com.au/assets/catalogueType/2/miscGraph/164405.png'
  window.embedded_svc.settings.displayHelpButton = true
  window.embedded_svc.settings.language = 'en'
  window.embedded_svc.settings.extraPrechatFormDetails = [
    {
      label: 'First Name',
      transcriptFields: ['PreChat_First_Name__c'],
    },
    {
      label: 'Last Name',
      transcriptFields: ['PreChat_Last_Name__c'],
    },
    {
      label: 'Email',
      transcriptFields: ['PreChat_Email__c'],
    },
    {
      label: 'ChatStartSite',
      value: 'ADR-Website',
      transcriptFields: ['Chat_Start_Site__c'],
    },
    {
      label: 'Brand',
      value: 'Adrenaline',
      transcriptFields: ['Brand__c'],
    },
    {
      label: 'RecordTypeId',
      value: '0127F000000ESNFQA4',
    },
  ]

  window.embedded_svc.settings.extraPrechatInfo = [
    {
      entityFieldMaps: [
        {
          doCreate: true,
          doFind: false,
          fieldName: 'LastName',
          isExactMatch: false,
          label: 'Last Name',
        },
        {
          doCreate: true,
          doFind: false,
          fieldName: 'FirstName',
          isExactMatch: false,
          label: 'First Name',
        },
        {
          doCreate: true,
          doFind: true,
          fieldName: 'Email',
          isExactMatch: true,
          label: 'Email',
        },
        {
          doCreate: true,
          doFind: true,
          fieldName: 'RecordTypeId',
          isExactMatch: true,
          label: 'RecordTypeId',
        },
      ],
      entityName: 'Contact',
      saveToTranscript: 'Contact',
      showOnCreate: true,
    },
  ]

  window.embedded_svc.settings.enabledFeatures = ['LiveAgent']
  window.embedded_svc.settings.entryFeature = 'LiveAgent'
  window.embedded_svc.init(
    'https://thebigredgroup.my.salesforce.com',
    'https://thebigredgroup.my.salesforce-sites.com/ADR',
    gslbBaseURL,
    '00D7F000000xEno',
    'Adrenaline_Site',
    {
      baseLiveAgentContentURL: 'https://c.la1-c1-it3.salesforceliveagent.com/content',
      deploymentId: '5727F000000D0ue',
      buttonId: '5737F000000Cuud',
      baseLiveAgentURL: 'https://d.la1-c1-it3.salesforceliveagent.com/chat',
      eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I1s0000000048EAA_16e10247f30',
      isOfflineSupportEnabled: false,
    }
  )
}
