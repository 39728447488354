import { useRouter } from 'next/router'
import Icon from '@/components/Icons'
import Label from '@/components/Label'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

import { SearchInteractionInput, SearchResult } from '@/types'
import {
  SectionWrapper,
  SearchInput,
  QueryInput,
  SearchHeader,
  PopoverWrapper,
  ResultsWrapper,
  PopoverPanelWrapper,
  Links,
  NoResults,
  ProductResults,
  CloseIcon,
  SearchMobileInputWrapper,
  MobileTopWrapper,
  ClearHistoryBtn,
  FuzzText,
  LiItemWrapper,
  LinksWrapper,
  SearchSuggestionColumn,
} from './SearchPopup.styles'
import { Fragment, forwardRef, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { Body, FlexibleHeading as Heading, Spacing } from '@big-red-group/storefront-common-ui'
import Button from '@/components/Button'
import ProductTile from './ProductTile'
import { fuzzySearch } from '@/helpers/UtilHelper'
import { logger } from '@/logging/Logger'
import { isClient } from '@/helpers/ClientSideRenderHelper'
import { DefaultPropertiesContext } from '@/context/DefaultPropertiesContext'
import { Navigation } from 'swiper/modules'
import { SwiperButtons, SwiperLeft, SwiperRight } from './Swiper.styles'
import { flattenCategories, pluralizeCompare } from '@/helpers/SearchHelper'
import { useProductClicked } from '@/hooks/AnalyticsProductHook'
import { useMediaQuery } from 'react-responsive'
import debounce from 'lodash.debounce'

const log = logger()

type SearchPopupProps = {
  onOpen: (isOpen?: boolean) => void
  trackOnClose: (isPerformedSearch?: boolean) => void
  onSearch: (interactionInput: SearchInteractionInput) => void
}

function LoadingIcon(props: { className?: string }) {
  return (
    <svg className={props.className} x="0px" y="0px" width="20px" height="20px" viewBox="0 0 50 50">
      <path
        fill="#000"
        d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
      >
        <animateTransform
          attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.75s"
          repeatCount="indefinite"
        ></animateTransform>
      </path>
    </svg>
  )
}

// eslint-disable-next-line react/display-name
const MyCustomButton = forwardRef(function (props: any, ref: any) {
  const { inputValue, searching } = props
  return (
    <div>
      <SearchInput id="search-input-ele" className={props['data-headlessui-state']}>
        <Icon className="search-icon" name="search" />
        <QueryInput
          id="searchInput"
          type="text"
          className={`search-popup-input ${props['data-headlessui-state']}`}
          placeholder="Search by location or adventure"
          data-testid="search-input"
          ref={ref}
          onChange={(e) => {
            props.onChange(e.target.value)
          }}
          onKeyDown={(e) => {
            if (e.code === 'Enter') {
              props.onPressEnter && props.onPressEnter(e.currentTarget.value)
            }
          }}
          onClick={
            props['data-headlessui-state'] === 'open'
              ? (e: any) => {
                  const rect = e.target.getBoundingClientRect()
                  const relativeX = e.clientX - rect.left
                  if (rect.width - relativeX <= 40 && e.target.value.length > 0) {
                    e.target.value = ''
                    props.onChange('')
                  }
                }
              : props.onClick
          }
        />
        {inputValue && inputValue.length > 0 && <Icon name="close" className="search-clear" />}
        {searching && <LoadingIcon className="loading" />}
      </SearchInput>
    </div>
  )
})

const SearchPopup: React.FC<SearchPopupProps> = ({ onOpen, trackOnClose, onSearch }) => {
  const router = useRouter()
  const { lang = 'en' } = router.query
  const trackProductClicked = useProductClicked()

  const [inputValue, setInputValue] = useState('')
  const [searching, setSearching] = useState(false)
  const defaultProperties = useContext(DefaultPropertiesContext)
  const allLocations = (defaultProperties?.searchProperties?.regions || []).map((a) => ({
    ...a,
    urlSegment: `/${lang}/things-to-do-${a.urlSegment}`,
  }))
  const allCategories = flattenCategories(defaultProperties?.searchProperties?.categories).map((a) => ({
    ...a,
    urlSegment: `/${lang}/adventures/${a.urlSegment}`,
  }))

  const defaultCategories = [
    {
      id: 1460,
      isParent: true,
      name: 'On Sale',
      urlSegment: `/${lang}/adventures/adventure-on-sale`,
    },
    {
      id: 1188,
      isParent: true,
      name: 'Flying Adventures',
      urlSegment: `/${lang}/adventures/flying-adventures`,
    },
    {
      id: 1189,
      isParent: true,
      name: 'Driving Adventures',
      urlSegment: `/${lang}/adventures/driving-adventures`,
    },
    {
      id: 1135,
      isParent: true,
      name: 'Water Sports',
      urlSegment: `/${lang}/adventures/water-sports`,
    },
    {
      id: 1187,
      isParent: true,
      name: 'Land Adventures',
      urlSegment: `/${lang}/adventures/land-adventures`,
    },
    {
      id: 1487,
      isParent: true,
      name: 'New Arrivals',
      urlSegment: `/${lang}/adventures/new-arrivals`,
    },
  ]

  const defaultLocations = [
    {
      id: 15,
      name: 'Sydney',
      urlSegment: `/${lang}/things-to-do-sydney`,
    },
    {
      id: 20,
      name: 'Melbourne',
      urlSegment: `/${lang}/things-to-do-melbourne`,
    },
    {
      id: 7,
      name: 'Gold Coast',
      urlSegment: `/${lang}/things-to-do-gold-coast`,
    },
    {
      id: 92,
      name: 'Hunter Valley',
      urlSegment: `/${lang}/things-to-do-hunter-valley`,
    },
    {
      id: 22,
      name: 'Perth',
      urlSegment: `/${lang}/things-to-do-perth`,
    },
    {
      id: 23,
      name: 'Adelaide',
      urlSegment: `/${lang}/things-to-do-adelaide`,
    },
  ]

  const hotProducts = [
    {
      id: 39161,
      urlSegment: `hot-air-balloon-ride-and-breakfast-hunter-valley`,
      name: 'Hot Air Balloon Ride & Breakfast - Hunter Valley - Weekday Special',
      operatorName: 'Beyond Ballooning',
      canonicalRegionUrlSegment: 'hunter-valley',
      fromPrice: 299,
      region: 'Hunter Valley',
      images: [
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/41476/image/538738.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/41476/image/538739.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/41476/image/538740.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/41476/image/538741.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/41476/image/538742.jpg',
      ],
    },
    {
      id: 24844,
      urlSegment: `tandem-skydive-over-great-ocean-road-weekend-12-000ft`,
      name: 'Tandem Skydive Over Great Ocean Road - 12,000ft',
      operatorName: 'Australian Skydive',
      canonicalRegionUrlSegment: 'great-ocean-road',
      fromPrice: 299,
      region: 'Great Ocean Road',
      images: [
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/21355/image/337317.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/21355/image/337304.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/21355/image/337301.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/21355/image/337310.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/21355/image/337307.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/21355/image/337313.jpg',
      ],
    },
    {
      id: 24088,
      urlSegment: `v8-5-drive-and-3-passenger-laps-combo-brisbane`,
      name: 'V8 5 Drive and 3 Passenger Laps Combo in Brisbane',
      operatorName: 'Queensland Raceway',
      canonicalRegionUrlSegment: 'brisbane',
      fromPrice: 368,
      region: 'Brisbane',
      images: [
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/33776/image/274880.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/33776/image/274881.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/33776/image/274878.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/33776/image/274879.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/33776/image/274882.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/33776/image/274883.jpg',
      ],
    },
    {
      id: 8282,
      urlSegment: `scenic-plane-flight-great-barrier-reef`,
      name: 'Hopper Scenic Flight over the Great Barrier Reef - 40 Minutes',
      operatorName: 'Cairns Scenic Plane Flights',
      canonicalRegionUrlSegment: 'cairns',
      fromPrice: 259,
      region: 'Cairns',
      images: [
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/20848/image/321493.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/20848/image/321474.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/20848/image/321499.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/20848/image/321478.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/20848/image/321481.jpg',
        'https://cdn.experienceoz.com.au/assets/productCatalogueType/20848/image/321487.jpg',
      ],
    },
  ]

  const [locations, setLocations] = useState(defaultLocations)
  const [categories, setCategories] = useState(defaultCategories)
  const [products, setProducts] = useState<any[]>(hotProducts)
  const [suggestions, setSuggestions] = useState<SearchResult[]>([])
  const [histories, setHistories] = useState<string[]>([])
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setHistories((localStorage.getItem('search_histories') || '').split(',').filter((a) => a))
  }, [])

  const attachScroll = (open: boolean) => {
    const ele = document.getElementById('search-input-ele')
    const handleScroll = () => {
      if (ele) {
        ele.style.transform = `translateY(${-(divRef.current?.scrollTop || 0)}px)`
      }
    }
    if (open && divRef.current && !divRef.current.getAttribute('scroll-attached')) {
      divRef.current?.addEventListener('scroll', handleScroll)
      divRef.current.setAttribute('scroll-attached', 'true')
    } else {
      divRef.current?.removeEventListener('scroll', handleScroll)
    }
  }

  const searchResults = async (val?: string) => {
    const query = val || ''
    setSearching(true)
    try {
      if (!query || query.length < 3) {
        setLocations(defaultLocations)
        setCategories(defaultCategories)
        setProducts(hotProducts)
        setSuggestions([])
      } else {
        const locs = fuzzySearch(query, allLocations)
        const cats = fuzzySearch(query, allCategories)
        setLocations(locs.matched)
        setCategories(cats.matched)
        setSuggestions([locs.fuzzied[0], cats.fuzzied[0]].filter((a) => a))
        const res = await (
          await fetch(`/api/search`, {
            method: 'POST',
            body: JSON.stringify({ query, limit: 4 }),
          })
        ).json()
        setProducts(res.products && res.products.length > 0 ? res.products : hotProducts)
      }
      updateHistories(query)
    } catch (e) {
      log.error('Error searching', {}, e as Error)
    } finally {
      setSearching(false)
    }
  }

  const handleQueryInput = useCallback(
    debounce((val) => searchResults(val), 600),
    []
  )

  const updateHistories = (val: string) => {
    if (!val.trim()) {
      return
    }
    val = val.trim()
    const items = []
    for (const item of histories || []) {
      const existed =
        item.toLowerCase().startsWith(val.toLowerCase()) || val.toLowerCase().startsWith(item.toLowerCase())
      items.push(existed ? val : item)
    }
    if (items.indexOf(val) < 0) {
      items.push(val)
    }
    setHistories([...new Set(items)])
    localStorage.setItem('search_histories', items.join(','))
  }

  const clearHistories = () => {
    setHistories([])
    localStorage.removeItem('search_histories')
  }

  const navigate = (url: string) => {
    window.location.href = `${window.location.origin}/${url}`
  }

  const getProductListTitle = (searchInputLength: number) => {
    return searchInputLength >= 3 ? 'Product Suggestions' : 'Popular right now'
  }

  const isMobile = useMediaQuery({ maxWidth: 1100 })
  const noProductSearched =
    hotProducts.map((a) => a.id).join(',') === products.map((a) => a.id).join(',') && inputValue.length >= 3
  return (
    <SectionWrapper data-testid="search-popup" id="search-popup-con">
      <SearchHeader>
        <PopoverWrapper>
          {({ open, close }) => {
            const onClose = (isPerformedSearch?: boolean) => {
              handleQueryInput('')
              setInputValue('')
              close()
              const eles = document.getElementsByClassName('search-popup-input') as unknown as HTMLInputElement[]
              for (const ele of eles) {
                ele.blur()
                ele.value = ''
              }
              if (isMobile) {
                // hack safari that icon display in wrong place when closed dialog in mobile
                const con = document.getElementById('search-popup-con')
                if (con) {
                  con.style.display = 'none'
                  setTimeout(() => {
                    con.style.display = 'block'
                  }, 10)
                }
              }
              trackOnClose(isPerformedSearch)
            }
            if (isClient() && isMobile) {
              if (open) {
                document.body.classList.add('noscroll')
              } else {
                document.body.classList.remove('noscroll')
              }
              attachScroll(open)
            }
            return (
              <>
                <Popover.Button
                  inputValue={inputValue}
                  searching={searching}
                  onChange={(v: string) => {
                    setInputValue(v)
                    setSearching(true)
                    handleQueryInput(v)
                  }}
                  onPressEnter={(val: string) => {
                    if (!val) {
                      return
                    }
                    const loc = allLocations.find((a) => pluralizeCompare(a.name, val))
                    const cat = allCategories.find((a) => pluralizeCompare(a.name, val))

                    onSearch({
                      isProductsSearched: true,
                      searchedInput: val,
                      searchedValue: cat?.name || loc?.name || val,
                      autocorrectValues: suggestions!,
                      isLocationSearched: loc ? true : false,
                      isCategorySearched: cat ? true : false,
                      suggestedCategories: categories?.slice(0, 6),
                      suggestedLocations: locations?.slice(0, 6),
                      totalProducts: products?.length,
                      displayProducts: products?.slice(0, 4),
                    })
                    onClose(true)
                    if (loc) {
                      navigate(`/${loc.urlSegment}`)
                    } else if (cat) {
                      navigate(`/${cat.urlSegment}`)
                    } else {
                      navigate(`/${lang}/search/${encodeURIComponent(val)}`)
                    }
                  }}
                  as={MyCustomButton}
                  onClick={onOpen}
                />
                <Popover.Overlay className="fixed inset-0 bg-black opacity-30" />
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <PopoverPanelWrapper ref={divRef}>
                    <SearchMobileInputWrapper>
                      <MobileTopWrapper data-testid="search-mobile-header">
                        <Heading size="L" color="text-inverse">
                          Search
                        </Heading>
                        <div
                          onClick={() => {
                            onClose()
                          }}
                        >
                          <Icon className="close" name="close" />
                        </div>
                      </MobileTopWrapper>
                      <div style={{ height: 50 }}></div>
                      <Spacing size="M" />
                    </SearchMobileInputWrapper>
                    <ResultsWrapper>
                      <SearchSuggestionColumn>
                        {suggestions.length === 0 &&
                          histories.length > 0 &&
                          categories.length === 0 &&
                          locations.length === 0 && (
                            <div>
                              {histories.length > 0 && (
                                <div>
                                  <Label as="span" color="brand-secondary">
                                    Recent Searches
                                  </Label>
                                  <Links>
                                    {histories.slice(histories.length - 5, histories.length).map((c) => (
                                      <LiItemWrapper key={c}>
                                        <Icon name="history" />{' '}
                                        <a
                                          onClick={() => {
                                            onClose(true)
                                          }}
                                          href={`/${lang}/search/${encodeURIComponent(c)}`}
                                        >
                                          {c}
                                        </a>
                                      </LiItemWrapper>
                                    ))}
                                  </Links>
                                  <Spacing size="M" />
                                  <ClearHistoryBtn onClick={clearHistories}>Clear history</ClearHistoryBtn>
                                </div>
                              )}
                            </div>
                          )}
                        {suggestions.length > 0 && (
                          <div>
                            <Label as="span" color="brand-secondary">
                              Did you mean
                            </Label>
                            <Links>
                              <LiItemWrapper>
                                <a
                                  onClick={() => {
                                    onClose(true)

                                    const loc = allLocations.find((a) => pluralizeCompare(a.name, suggestions[0].name))
                                    const cat = allCategories.find((a) => pluralizeCompare(a.name, suggestions[0].name))
                                    onSearch({
                                      isProductsSearched: true,
                                      searchedInput: inputValue,
                                      searchedValue: suggestions[0].name,
                                      autocorrectValues: suggestions!,
                                      isLocationSearched: loc ? true : false,
                                      isCategorySearched: cat ? true : false,
                                      suggestedCategories: categories.slice(0, 6),
                                      suggestedLocations: locations.slice(0, 6),
                                      totalProducts: products.length,
                                      displayProducts: products.slice(0, 4),
                                      isAutoCorrectionClicked: true,
                                    })
                                  }}
                                  href={suggestions[0].urlSegment}
                                >
                                  <FuzzText>{` ${suggestions[0].name}`}</FuzzText>
                                </a>
                              </LiItemWrapper>
                              {suggestions[1] && (
                                <LiItemWrapper>
                                  <a
                                    onClick={() => {
                                      onClose(true)
                                      const loc = allLocations.find((a) =>
                                        pluralizeCompare(a.name, suggestions[0].name)
                                      )
                                      const cat = allCategories.find((a) =>
                                        pluralizeCompare(a.name, suggestions[0].name)
                                      )

                                      onSearch({
                                        isProductsSearched: true,
                                        searchedInput: inputValue,
                                        searchedValue: suggestions[1].name,
                                        autocorrectValues: suggestions!,
                                        isLocationSearched: loc ? true : false,
                                        isCategorySearched: cat ? true : false,
                                        suggestedCategories: categories.slice(0, 6),
                                        suggestedLocations: locations.slice(0, 6),
                                        totalProducts: products.length,
                                        displayProducts: products.slice(0, 4),
                                        isAutoCorrectionClicked: true,
                                      })
                                    }}
                                    href={suggestions[1].urlSegment}
                                  >
                                    <FuzzText>{` ${suggestions[1].name}`}</FuzzText>
                                  </a>
                                </LiItemWrapper>
                              )}
                            </Links>
                          </div>
                        )}
                        {categories.length > 0 && (
                          <div>
                            <Label as="span" color="brand-secondary">
                              Categories
                            </Label>
                            <Links>
                              {[...categories].slice(0, 6).map((c) => (
                                <LiItemWrapper key={c.name}>
                                  <a
                                    onClick={() => {
                                      onClose(true)
                                      onSearch({
                                        isProductsSearched: true,
                                        searchedInput: inputValue,
                                        searchedValue: c.name,
                                        autocorrectValues: suggestions!,
                                        isSuggestedCategoryClicked: true,
                                        isCategorySearched: true,
                                        suggestedCategories: categories?.slice(0, 6),
                                        suggestedLocations: locations?.slice(0, 6),
                                        totalProducts: products?.length,
                                        displayProducts: products?.slice(0, 4),
                                        isAutoCorrectionClicked: false,
                                      })
                                    }}
                                    href={c.urlSegment}
                                  >
                                    {c.name}
                                  </a>
                                </LiItemWrapper>
                              ))}
                            </Links>
                          </div>
                        )}
                        {locations.length > 0 && (
                          <div>
                            <Label as="span" color="brand-secondary">
                              Locations
                            </Label>
                            <Links>
                              {[...locations].slice(0, 6).map((c) => (
                                <LiItemWrapper key={c.name}>
                                  <a
                                    onClick={() => {
                                      onClose(true)

                                      onSearch({
                                        isProductsSearched: true,
                                        searchedInput: inputValue,
                                        searchedValue: c.name,
                                        autocorrectValues: suggestions!,
                                        isSuggestedLocationClicked: true,
                                        isLocationSearched: true,
                                        suggestedCategories: categories?.slice(0, 6),
                                        suggestedLocations: locations?.slice(0, 6),
                                        totalProducts: products?.length,
                                        displayProducts: products?.slice(0, 4),
                                        isAutoCorrectionClicked: false,
                                      })
                                    }}
                                    href={c.urlSegment}
                                  >
                                    {c.name}
                                  </a>
                                </LiItemWrapper>
                              ))}
                            </Links>
                          </div>
                        )}
                      </SearchSuggestionColumn>
                      <div>
                        <Label as="span" color="brand-secondary">
                          {getProductListTitle(inputValue.length)}
                        </Label>
                        <Spacing size="S" />
                        {noProductSearched && (
                          <>
                            <NoResults>
                              {searching
                                ? 'Searching...'
                                : `Oops! We don't have any adventures related to "${inputValue}"`}
                            </NoResults>
                            <Spacing size="L" />
                            <Label as="span" color="brand-secondary">
                              Popular right now
                            </Label>
                          </>
                        )}

                        <ProductResults>
                          {products && products.length > 0 && (
                            <>
                              <Swiper
                                breakpoints={{
                                  0: {
                                    spaceBetween: 16,
                                    slidesPerView: 'auto',
                                  },
                                  768: {
                                    spaceBetween: 16,
                                    slidesPerView: 4,
                                  },
                                  1100: {
                                    spaceBetween: 24,
                                    slidesPerView: 2,
                                  },
                                }}
                                navigation={{ prevEl: `.prod-results-prev`, nextEl: `.prod-results-next` }}
                                modules={[Navigation]}
                              >
                                {products
                                  .slice(0, 4)
                                  .map(
                                    (
                                      {
                                        id,
                                        name,
                                        urlSegment,
                                        operatorName,
                                        retailPrice,
                                        fromPrice,
                                        region,
                                        primaryRegion,
                                        canonicalRegionUrlSegment,
                                        images,
                                        rating,
                                        regions,
                                      },
                                      index
                                    ) => (
                                      <SwiperSlide key={id}>
                                        <ProductTile
                                          id={id}
                                          title={name}
                                          supplier={operatorName}
                                          price={retailPrice}
                                          fromPrice={fromPrice}
                                          region={region || regions[0]}
                                          canonicalRegionUrlSegment={canonicalRegionUrlSegment}
                                          image={images[0]}
                                          url={`/${lang}/${canonicalRegionUrlSegment || primaryRegion}/${urlSegment}`}
                                          onClick={() => {
                                            onClose(true)
                                            trackProductClicked({
                                              product: products[index],
                                              position: index + 1,
                                              isRecommended: true,
                                              listTitle: `${getProductListTitle(inputValue.length)}: Topbar Search`,
                                              isCarousel: true,
                                            })
                                            onSearch({
                                              isProductsSearched: true,
                                              searchedInput: inputValue,
                                              autocorrectValues: suggestions!,
                                              isSuggestedLocationClicked: false,
                                              isSuggestedCategoryClicked: false,
                                              suggestedCategories: categories?.slice(0, 6),
                                              suggestedLocations: locations?.slice(0, 6),
                                              isProductClicked: true,
                                              totalProducts: products?.length,
                                              displayProducts: products?.slice(0, 4),
                                              product: products[index],
                                              isAutoCorrectionClicked: false,
                                              searchedValue: products[index].name,
                                            })
                                          }}
                                          mobileCardTile={false}
                                          rating={rating}
                                        />
                                      </SwiperSlide>
                                    )
                                  )}
                              </Swiper>
                              {products.length >= 4 && (
                                <>
                                  <Spacing size="L" />
                                  {inputValue.length >= 3 ? (
                                    <Button
                                      size="S"
                                      className="see-more"
                                      onClick={() => {
                                        navigate(`/${lang}/search/${encodeURIComponent(inputValue)}`)
                                        onClose()
                                      }}
                                    >
                                      See more results
                                    </Button>
                                  ) : (
                                    <Button
                                      size="S"
                                      className="see-more"
                                      onClick={() => {
                                        navigate(`${lang}/adventures/adventure-on-sale`)
                                        onClose()
                                      }}
                                    >
                                      See popular deals
                                    </Button>
                                  )}
                                </>
                              )}
                              <SwiperButtons>
                                <SwiperLeft className={`prod-results-prev`}></SwiperLeft>
                                <SwiperRight className={`prod-results-next`}></SwiperRight>
                              </SwiperButtons>
                            </>
                          )}
                        </ProductResults>
                      </div>
                    </ResultsWrapper>
                    <CloseIcon
                      onClick={() => {
                        onClose()
                      }}
                    >
                      <Icon name="close" />
                    </CloseIcon>
                  </PopoverPanelWrapper>
                </Transition>
              </>
            )
          }}
        </PopoverWrapper>
      </SearchHeader>
    </SectionWrapper>
  )
}

export default SearchPopup
